import './index.scss'


$('.footer .right-nav ul .nav-title .iconfont').on('click',function(){
  let $parents = $(this).parents('ul')
  if($parents.hasClass('active')){
    $parents.removeClass('active').find('.sub-title').slideUp()
  }else{
    $parents.addClass('active').find('.sub-title').slideDown()
    $parents.siblings().removeClass('active').find('.sub-title').slideUp()
  }
})
import './index.scss'

$('.authority .authority-mask,.authority .close-btn').on('click',function(){
  $('.authority').fadeOut()
})
$('.authoritydown .authority-mask,.authoritydown .close-btn').on('click',function(){
  $('.authoritydown').fadeOut()
})

// !(function (){
//   $('.authority').fadeIn()
// })()

import {WOW} from '../../../node_modules/wowjs/dist/wow';
var wow = new WOW({
  offset: 100,
  live: true,
  mobile: false,
});
wow.init();

let istextList = localStorage.getItem('istextListstate')

$(document).ready(function(){
  if(istextList == 'false'){
    $('.switch-btn').removeClass('show-text-list')
    $('.switch-list').removeClass('text-list-show')
  }else if(istextList == 'true'){
    $('.switch-btn').addClass('show-text-list')
    $('.switch-list').addClass('text-list-show')
  }
})

$('.switch-btn').on('click',function(){
  if($(this).hasClass('show-text-list')){
    $(this).removeClass('show-text-list')
    $('.switch-list').removeClass('text-list-show')
    localStorage.setItem('istextListstate', false);
  }else{
    $(this).addClass('show-text-list')
    $('.switch-list').addClass('text-list-show')
    localStorage.setItem('istextListstate', true);
  }
})

$('.select').on('click','input',function(){
  let $parent = $(this).closest('.select')
  if(!$parent.hasClass('active')){
    $('.select').removeClass('active').find('ul').slideUp()
    $parent.addClass('active').find('ul').slideDown()
  }
})
$('.select').on('click','ul li',function(){
  let value = $(this).attr('data-val')
  let text = $(this).html()
  let $parent = $(this).closest('.select')
  $(this).addClass('cur').siblings().removeClass('cur')
  $parent.find('input').val(text).attr('data-value',value)
  $parent.removeClass('active').find('ul').slideUp()
})

$('body').on('click',function(e){
  if($(e.target).parents('.select').length == 0){
    $('.select').removeClass('active').find('ul').slideUp()
  }
})

$('.item-textarea .open-text').on('click',function(){
  let $parent = $(this).closest('.item-textarea')
  $parent.find('.textarea-mask').fadeIn()
  $parent.find('.textarea').addClass('show-mob')
})
$('.textarea-mask,.item-textarea .close-text,.item-textarea .done-text').on('click',function(){
  let $parent = $(this).closest('.item-textarea')
  $parent.find('.textarea-mask').fadeOut()
  $parent.find('.textarea').removeClass('show-mob')
})

import './index.scss'
import Swiper from 'swiper/js/swiper.min.js'

//pc端顶部下拉
$('.pc-header .top-link .show-cont .item').on('click',function(){
  let index = $(this).index()
  if($(this).hasClass('active')){
    $(this).removeClass('active')
    $('.pc-header .top-link .hidden-cont .hidden-cont-item').eq(index).slideUp(500)
    setTimeout(function(){
      $('.pc-header .top-link').removeClass('active')
    },500)
  }else{
    $('.pc-header .top-link').addClass('active')
    $(this).addClass('active').siblings().removeClass('active')
    $('.pc-header .top-link .hidden-cont .hidden-cont-item').eq(index).slideDown(500).siblings().slideUp(500)
  }
})

//鼠标经过显示子菜单
var mouseover_tid = [];
var mouseout_tid = [];
$(document).ready(function () {
  $(".pc-header .header .nav li").each(function (index) {
    $(this).hover(
      // 鼠标进入
      function () {
        var _self = this;
        // 停止卷起事件
        clearTimeout(mouseout_tid[ index ]);
        // 当鼠标进入超过 0.2 秒, 展开菜单, 并记录到线程 ID 中
        mouseover_tid[ index ] = setTimeout(function () {
          if($(window).width() > 1024){
            $(_self).addClass('active').find('.sub-nav').slideDown(200)
          }
        }, 201);
      },
      // 鼠标离开
      function () {
        var _self = this;
        // 停止展开事件
        clearTimeout(mouseover_tid[ index ]);
        // 当鼠标离开超过 0.2 秒, 卷起菜单, 并记录到线程 ID 中
        mouseout_tid[ index ] = setTimeout(function () {
          if($(window).width() > 1024){
            $(_self).removeClass('active').find('.sub-nav').slideUp(200)
          }
        }, 201);
      });
    });
});

var mouseover_tid2 = [];
var mouseout_tid2 = [];
$(document).ready(function () {
  $(".pc-header .header .nav").each(function (index) {
    $(this).hover(
      // 鼠标进入
      function () {
        var _self = this;
        // 停止卷起事件
        clearTimeout(mouseout_tid2[ index ]);
        // 当鼠标进入超过 0.2 秒, 展开菜单, 并记录到线程 ID 中
        mouseover_tid2[ index ] = setTimeout(function () {
          if($(window).width() > 1024){
            $('header .mask').fadeIn()
          }
        }, 201);
      },
      // 鼠标离开
      function () {
        var _self = this;
        // 停止展开事件
        clearTimeout(mouseover_tid2[ index ]);
        // 当鼠标离开超过 0.2 秒, 卷起菜单, 并记录到线程 ID 中
        mouseout_tid2[ index ] = setTimeout(function () {
          if($(window).width() > 1024){
            $('header .mask').fadeOut()
          }
        }, 201);
      });
    });
});

$('body').on('click',function(e){
  if($(e.target).parents('.pc-header .header .nav').length == 0){
    $('.pc-header .header .nav li').removeClass('show-sub on').find('.sub-nav').slideUp()
    $('header .mask').fadeOut()
    $('.pc-header').removeClass('active')
  }
  if($(e.target).parents('.header-aside').length == 0){
    $('.header-aside .aside-link').removeClass('show')
  }
})

//移动端导航
$('[data-open="cmenu"]').on('click', function () {
  if($('.header .right-fun .hamburger').hasClass('active')){
    $('.header .right-fun .hamburger').removeClass('active')
    $('.mob-header').removeClass('active')
    $('.pc-header').removeClass('mob-active')
    $('.mob-header>ul>li .sub-title').removeClass('active')
  }else{
    $('.header .right-fun .hamburger').addClass('active')
    $('.mob-header').addClass('active')
    $('.pc-header').addClass('mob-active')
  }
})

$('.mob-header>ul>li .nav-title .iconfont').on('click',function(){
  let $parents = $(this).closest('li')
  if($parents.hasClass('active')){
    $parents.removeClass('active').find('.sub-title').slideUp()
  }else{
    $parents.siblings().removeClass('active').find('.sub-title').slideUp()
    $parents.addClass('active').find('.sub-title').slideDown()
  }
})
// $('.mob-header>ul>li .sub-title .back-main-nav').on('click',function(){
//   let $parents = $(this).closest('.sub-title')
//   $parents.removeClass('active')
// })

$('.mob-header>ul .icon-nav>li .nav-title').on('click',function(){
  let $parents = $(this).closest('li')
  $parents.find('.sub-title').show().addClass('active')
})
$('.mob-header>ul .icon-nav>li .sub-title .back-main-nav').on('click',function(){
  let $parents = $(this).closest('.sub-title')
  $parents.removeClass('active')
})

$('.mob-header .slide-nav li .slide-title .iconfont').on('click',function(){
  let $parents = $(this).closest('li')
  if($parents.hasClass('active')){
    $parents.removeClass('active').find('.slide-cont').slideUp()
  }else{
    $parents.addClass('active').find('.slide-cont').slideDown()
    $parents.siblings().removeClass('active').find('.slide-cont').slideUp()
  }
})

//侧导航
$('.header-aside .btn-icon .message').on('click',function(){
  $('.header-aside .aside-link').addClass('show')
})
$('.header-aside .aside-link .aside-link-close').on('click',function(){
  $('.header-aside .aside-link').removeClass('show')
})
$('.header-aside .aside-link a').on('click',function(){
  $('.header-aside .aside-link').removeClass('show')
})
//返回顶部
$('.header-aside .back-top').on('click',function(){
  $("html, body").animate({
    scrollTop: 0
  }, {
      duration: 500,
      easing: "swing"
  });
  return false;
})

// 移动端侧导航
$('.mob-aside .aside-btn .open-shop').on('click',function(){
  $('.mob-aside .aside-mask').fadeIn()
  $('.mob-aside .mob-shop').addClass('show-mob')
})
$('.mob-aside .aside-btn .open-contact').on('click',function(){
  $('.mob-aside .aside-mask').fadeIn()
  $('.mob-aside .mob-contact').addClass('show-mob')
})
$('.mob-aside .mob-aside-title').on('click',function(){
  $('.mob-aside .aside-mask').fadeOut()
  $('.mob-aside .mob-shop').removeClass('show-mob')
  $('.mob-aside .mob-contact').removeClass('show-mob')
})
$('.mob-aside .aside-mask').on('click',function(){
  $('.mob-aside .aside-mask').fadeOut()
  $('.mob-aside .mob-shop').removeClass('show-mob')
  $('.mob-aside .mob-contact').removeClass('show-mob')
})

//header搜索
$('.pc-header .nav-search .open-search').on('click',function(){
  if($(window).width()>=768){
    if($('.pc-header .header .right-fun .nav-search').hasClass('active')){
      $('.pc-header .header .right-cont .nav').removeClass('hide')
      $('.pc-header .header .right-fun .nav-search').removeClass('active')
      $('.pc-header .header .right-fun .nav-search').removeClass('active')
      $('#header-search-input').blur()
    }else{
      $('.pc-header .header .right-cont .nav').addClass('hide')
      $('.pc-header .header .right-fun .nav-search').addClass('active')
      setTimeout(function(){
        $('#header-search-input').focus()
      },100)
    }
  }else{
    $('header .mob-search').addClass('show-mob')
  }
})
$('header .mob-search .close-search').on('click',function(){
  $('header .mob-search').removeClass('show-mob')
})



//header产品swiper
var header_pro_swiper = new Swiper(".nav-pro-swiper .mySwiper", {
  loop: true,
  speed: 800,
  observer: true,
  observeParents: true,
  autoplay: {
    delay: 3000,
    stopOnLastSlide: false,
    disableOnInteraction: false,
    waitForTransition: true,
  },
  pagination: {
    el: ".nav-pro-swiper .swiper-pagination",
    clickable: true,
    renderBullet: function (index, className) {
      return '<div class="' + className + '"><svg><circle></circle></svg></div>';
    },
  },
});
var header_pro_nav_swiper = new Swiper(".nav-pro-icon .mySwiper", {
  speed: 600,
  watchOverflow: true,
  observer: true,
  observeParents: true,
  pagination: {
    el: ".nav-pro-icon .swiper-pagination",
    clickable: true,
  },
});

//header解决方案swiper
$(".header .nav .sub-nav .nav-solution-icon a").eq(0).addClass('active')
$(".header .nav .sub-nav .nav-solution-icon a").hover(function(){
  let index = $(this).index()
  $(".header .nav .sub-nav .nav-solution-icon a").eq(index).addClass('active').siblings().removeClass('active')
  header_solution_swiper.slideTo(index)
})
var header_solution_swiper = new Swiper(".nav-solution-swiper .mySwiper", {
  speed: 400,
  observer: true,
  observeParents: true,
  allowTouchMove: false,
  effect : 'fade',
  fadeEffect: {
    crossFade: true,
  },
  // pagination: {
  //   el: ".nav-solution-swiper .swiper-pagination",
  // },
});

var beforeScroH = $(document).scrollTop();
$(document).scroll(function () {
  var scroH = $(document).scrollTop(); //滚动高度
  var viewH = $(window).height(); //可见高度
  var contentH = $(document).height(); //内容高度

  if (scroH > viewH / 2) {
    $('.header-aside .btn-icon').addClass('scroll')
    $('.mob-aside .aside-btn').addClass('show-mob')
  }else{
    $('.header-aside .btn-icon').removeClass('scroll')
    $('.mob-aside .aside-btn').removeClass('show-mob')
  }

  if(scroH > 40){
    $('.pc-header .header').addClass('fixed')
    $('.pc-header .top-link').addClass('scroll')
  }else{
    $('.pc-header .header').removeClass('fixed')
    $('.pc-header .top-link').removeClass('scroll')
  }

  //判断页面是上滚还是下滚
  var delta = scroH - beforeScroH;
  if($(window).width()>1024){
    if (delta > 0) {
      if(scroH > 100){
        $('.pc-header .header').addClass('trans')
      }
    }
    if (delta < 0) {
      $('.pc-header .header').removeClass('trans')
    }
  }
  beforeScroH = scroH;
});
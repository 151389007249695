import {provinceList} from './area.js';

var province=$("#province"),city=$("#city"),town=$("#town");
var provinceul=$("#province-ul"),cityul=$("#city-ul"),townul=$("#town-ul");
for(var i=0;i<provinceList.length;i++){
    addEle(provinceul,provinceList[i].name);
}
function addEle(ele,value){
    var optionStr="";
    optionStr="<li data-val="+value+">"+value+"</li>";
    ele.append(optionStr);
}
function removeEle(ele){
    ele.find("li").remove();
    ele.siblings("input").val('');
    var optionStar="<li data-val="+"请选择"+" class='disable'>"+"请选择"+"</li>";
    ele.append(optionStar);
}
var provinceText,cityText,cityItem;

// province.on("input",function(){
//     provinceText=$(this).val();
//     console.log(provinceText)
//     $.each(provinceList,function(i,item){
//         if(provinceText == item.name){
//             cityItem=i;
//             return cityItem
//         }
//     });
//     removeEle(cityul);
//     removeEle(townul);
//     $.each(provinceList[cityItem].cityList,function(i,item){
//         addEle(cityul,item.name)
//     })
// });
provinceul.on("click","li",function(){
    provinceText=$(this).attr('data-val');
    // console.log(provinceText)
    $.each(provinceList,function(i,item){
        if(provinceText == item.name){
            cityItem=i;
            return cityItem
        }
    });
    removeEle(cityul);
    removeEle(townul);
    $.each(provinceList[cityItem].cityList,function(i,item){
        addEle(cityul,item.name)
    })
});
// city.on("input",function(){
//     cityText=$(this).val();
//     removeEle(town);
//     $.each(provinceList,function(i,item){
//         if(provinceText == item.name){
//             cityItem=i;
//             return cityItem
//         }
//     });
//     $.each(provinceList[cityItem].cityList,function(i,item){
//         if(cityText == item.name){
//             for(var n=0;n<item.areaList.length;n++){
//                 addEle(town,item.areaList[n])
//             }
//         }
//     });
// });
cityul.on("click","li",function(){
    cityText=$(this).attr('data-val');
    removeEle(townul);
    $.each(provinceList,function(i,item){
        if(provinceText == item.name){
            cityItem=i;
            return cityItem
        }
    });
    $.each(provinceList[cityItem].cityList,function(i,item){
        if(cityText == item.name){
            for(var n=0;n<item.areaList.length;n++){
                addEle(townul,item.areaList[n])
            }
        }
    });
});
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!bannerPath) var bannerPath='';
if (!title) var title = '';
if (!desc) var desc = '';
;
__p += '\r\n<section class="ny-banner">\r\n  <b class="bg-img" style="background-image: url(' +
((__t = ( bannerPath )) == null ? '' : __t) +
');"></b>\r\n  <b class="bg-img-mob" style="background-image: url(' +
((__t = ( bannerPath )) == null ? '' : __t) +
');"></b>\r\n  <div class="video pc">\r\n    <video muted autoplay loop poster="' +
((__t = ( bannerPath )) == null ? '' : __t) +
'" src="https://www.runoob.com/try/demo_source/movie.mp4" x5-video-player-type="h5" x5-video-player-fullscreen="true" webkit-playsinline="" playsinline=""></video>\r\n  </div>\r\n  <div class="video mob">\r\n    <video muted autoplay loop poster="' +
((__t = ( bannerPath )) == null ? '' : __t) +
'" src="https://www.runoob.com/try/demo_source/movie.mp4" x5-video-player-type="h5" x5-video-player-fullscreen="true" webkit-playsinline="" playsinline=""></video>\r\n  </div>\r\n  <div class="t-cont">\r\n    <h1 class="wow fadeInUp">' +
((__t = ( title )) == null ? '' : __t) +
'</h1>\r\n    <div class="desc wow fadeInUp">' +
((__t = ( desc )) == null ? '' : __t) +
'</div>\r\n  </div>\r\n</section>';

}
return __p
}
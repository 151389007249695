import './index.scss'
import Swiper from 'swiper/js/swiper.min.js'

var nyNavswiper
var nyswiperisinit = false
var winW = $(window).width()
var beforeScroH = $(document).scrollTop();
$(document).scroll(function () {
  var scroH = $(document).scrollTop(); //滚动高度
  var delta = scroH - beforeScroH;
  if($('.ny-banner').length > 0){
    $('.ny-banner .bg-img').css('transform','translateY('+ scroH / 3 +'px)')
  }
  if($('.ny-nav').length > 0){
    let nyNavH = $('.ny-nav').offset().top
    let tranH = $('.pc-header .header').outerHeight()-1

    if(scroH > nyNavH - $('.ny-nav').height()){
      if($(window).width()>1024){
        $('.ny-nav .ny-nav-fixed').addClass('fixed')
      }else{
        $('.ny-nav .ny-nav-fixed').addClass('fixed').css('transform','translateY('+ tranH +'px)')
      }
    }else{
      if($(window).width()>1024){
        $('.ny-nav .ny-nav-fixed').removeClass('fixed')
      }else{
        $('.ny-nav .ny-nav-fixed').removeClass('fixed').css('transform','translateY(0px)')
      }
    }

    if($(window).width()>1024){
      if(scroH > nyNavH){
        if (delta > 0) {
          $('.ny-nav .ny-nav-fixed').css('transform','translateY(0px)')
        }
        if (delta < 0) {
          $('.ny-nav .ny-nav-fixed').css('transform','translateY('+ tranH +'px)')
        }
      }else{
        $('.ny-nav .ny-nav-fixed').css('transform','translateY(0px)')
      }
    }
  }
  beforeScroH = scroH;
});

$(document).ready(function(){
  if($('.ny-nav').length > 0 && !$('.ny-nav').hasClass('pro-ny-nav')){
    navswiperinit()
    let index = $('.ny-nav .mySwiper .swiper-slide.active').index()
    nyNavswiper.slideTo(index,0)
  }
  setnyNavHeight()
})
$(window).resize(function(){
  setnyNavHeight()
})

function navswiperinit(){
  nyNavswiper = new Swiper(".ny-nav .mySwiper", {
    slidesPerView:  6, 
    resizeObserver: true,
    watchOverflow: true,//因为仅有1个slide，swiper无效
    navigation: {
      nextEl: ".ny-nav .swiper-button-next",
      prevEl: ".ny-nav .swiper-button-prev",
    },
    breakpoints: {
      1025: {
        slidesPerView: 7.5,
      },
      769: {
        slidesPerView: 6,
      },
      640: {
        slidesPerView: 6,
      }
    },
    on: {
      init: function(swiper){
        if(winW >= 768){
          if(this.isLocked){
            $('.ny-nav .swiper-btn').hide()
          }else{
            $('.ny-nav .swiper-btn').show()
          }
        }
      },
      resize: function(){
        this.update(); //窗口变化时，更新Swiper的一些属性，如宽高等
        if(winW >= 768){
          if(this.isLocked){
            $('.ny-nav .swiper-btn').hide()
          }else{
            $('.ny-nav .swiper-btn').show()
          }
        }
      },
    },
  });
}

function setnyNavHeight(){
  if($('.ny-nav').length > 0){
    setTimeout(function(){
      let H = $('.ny-nav .ny-nav-fixed .cont').height() / 2
      $('.ny-nav').css('height', H + 'px');
    },200)
  }
}
